<template>
  <RecordViewWrapper>
    <sdPageHeader title="Kebutuhan Tenaga Kerja Divisi Manajemen">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create laporan manajemen kebutuhan dan realisasi tk')"
        >
          <router-link to="/laporan-manajemen/kebutuhan-tk-divisi-manajemen/add">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :dataSource="dataSource" :columns="columns" :pagination="false" bordered />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove kebutuhan tenaga kerja {{ modalState.name }}
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
  },
  {
    title: 'Kebutuhan',
    children: [
      {
        title: 'Struktural',
        align: 'right',
        dataIndex: 'kebutuhan_struktural',
      },
      {
        title: 'Fungsional',
        align: 'right',
        dataIndex: 'kebutuhan_fungsional',
      },
    ],
  },
  {
    title: 'Existing',
    children: [
      {
        title: 'Struktural',
        align: 'right',
        dataIndex: 'existing_struktural',
      },
      {
        title: 'Fungsional',
        align: 'right',
        dataIndex: 'existing_fungsional',
      },
    ],
  },
  {
    title: 'Gap',
    children: [
      {
        title: 'Struktural',
        align: 'right',
        dataIndex: 'gap_struktural',
      },
      {
        title: 'Fungsional',
        align: 'right',
        dataIndex: 'gap_fungsional',
      },
    ],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const modalState = reactive({
      isLoading: false,
      visible: false,
      name: '',
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'laporan-tk-divisi-manajemen');
    };

    const handleDelete = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus ${name}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'laporan-tk-divisi-manajemen',
            getData: () => {
              dispatch('axiosCrudGetData', 'laporan-tk-divisi-manajemen');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map((data, key) => {
            const {
              id,
              name,
              description,
              kebutuhan_struktural,
              kebutuhan_fungsional,
              existing_struktural,
              existing_fungsional,
              gap_struktural,
              gap_fungsional,
              status,
            } = data;

            let color;

            switch (status) {
              case 'CRTD':
                color = 'blue';
                break;
              case 'UPDT':
                color = 'orange';
                break;
              case 'REL':
                color = 'green';
                break;
              case 'UNREL':
                color = 'volcano';
                break;
              case 'DEL':
                color = 'red';
                break;
            }

            let update = checkPermission('update laporan manajemen kebutuhan dan realisasi tk') ? (
              <router-link class="edit" to={`/laporan-manajemen/kebutuhan-tk-divisi-manajemen/${id}`}>
                <sdFeatherIcons type="edit" size={14} title="Ubah" />
              </router-link>
            ) : null;

            let approve = checkPermission('approve laporan manajemen kebutuhan dan realisasi tk') ? (
              <a class="edit" onClick={() => handleApprove(id, name)}>
                <sdFeatherIcons type="check-square" size={14} title="Approve" />
              </a>
            ) : null;

            let del = checkPermission('delete laporan manajemen kebutuhan dan realisasi tk') ? (
              <router-link class="delete" onClick={() => handleDelete(id, data.name)} to="">
                <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
              </router-link>
            ) : null;

            let unapprove = checkPermission('approve laporan manajemen kebutuhan dan realisasi tk') ? (
              <a class="edit" onClick={() => handleUnapprove(id, name)}>
                <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
              </a>
            ) : null;

            return {
              key: key + 1,
              name,
              description,
              kebutuhan_struktural,
              kebutuhan_fungsional,
              existing_struktural,
              existing_fungsional,
              gap_struktural,
              gap_fungsional,
              status: <a-tag color={color}>{status}</a-tag>,
              action: (
                <div class="table-actions">
                  {status != 'REL' ? (
                    <>
                      {update}
                      {approve}
                      {del}
                    </>
                  ) : (
                    { unapprove }
                  )}
                </div>
              ),
            };
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'laporan-tk-divisi-manajemen', filter: formState.searchItem });
    };

    const handleApprove = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve kebutuhan tenaga kerja ${name}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-laporan-tk-divisi-manajemen',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleUnapprove = (id, name) => {
      formModalState.id = id;
      modalState.name = name;
      modalState.visible = true;
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-laporan-tk-divisi-manajemen/' + formModalState.id, {
        alasan: formModalState.alasan,
      }).then(() => {
        modalState.visible = false;
        modalState.isLoading = false;
        formModalState.id = null;
        formModalState.alasan = '';

        getData();
      });
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      labelCol,
      wrapperCol,
      modalState,
      handleCancel,
      doUnapprove,
      formModalState,
      modalRules,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
